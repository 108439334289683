<template>
  <base-card>
    <portal to="navBarEnd">
      <base-button
        @click="openCustomerPortal"
        :disabled="getIsProvidingService"
        :loading="portalLoading"
      >
        {{ $t("subscription.manage") }}
      </base-button>
    </portal>

    <v-stepper v-model="currentStep" alt-labels>
      <v-stepper-header>
        <v-stepper-step
          :complete="currentStep > 1 || getIsSubscriptionUpdated"
          step="1"
        >
          <p class="text-center">{{ $t("subscription.validateDevices") }}</p>
        </v-stepper-step>

        <v-divider />

        <v-stepper-step
          :complete="currentStep > 2 || getIsSubscriptionUpdated"
          step="2"
        >
          <span class="text-center">{{ $t("common.payment") }}</span>
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content class="pa-0" step="1">
          <subscription-device-scan :devices="getAllOwnedDevices">
            <template #title>
              <v-icon class="mr-4" color="primary" x-large>
                {{ resultIcon }}
              </v-icon>
              <span class="text-center text-lg-left">{{ resultText }}</span>
            </template>
            <template #subtitle>
              <v-row>
                <v-spacer />
                <v-col class="d-flex justify-center">
                  <v-card-subtitle>
                    <v-icon color="primary">mdi-database</v-icon>
                    {{ totalFormatted }}
                  </v-card-subtitle>
                </v-col>
                <v-spacer />
              </v-row>
              <v-divider class="my-4" />
            </template>
          </subscription-device-scan>
        </v-stepper-content>
        <v-stepper-content step="2">
          <SubscriptionCard
            :disabled="getIsProvidingService"
            :loading="loading"
            :old-subtotal-label="oldSubtotalTextLabel"
            :subtotal-label="subtotalTextLabel"
            @click="updateSubscription"
          />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

    <v-footer
      app
      class="py-4"
      elevation="24"
      inset
      padless
      style="background-color: whitesmoke"
    >
      <v-row align="center">
        <v-col
          class="d-flex justify-lg-center justify-start ms-lg-0 ms-4"
          cols="4"
          lg="2"
        >
          <base-button
            v-if="currentStep !== 1"
            :disabled="loading"
            class="pr-6"
            outlined
            text
            @click="currentStep = 1"
          >
            <v-icon> mdi-chevron-left </v-icon>
            {{ $t("common.goBack") }}
          </base-button>

          <v-label v-else>
            {{ $t("subscription.totalPerMonth", { subtotalTextLabel }) }}
          </v-label>
        </v-col>

        <v-spacer />

        <v-col
          class="d-flex justify-lg-center justify-end me-lg-0 me-2"
          cols="4"
          lg="2"
        >
          <base-button
            v-if="!getIsSubscriptionUpdated && currentStep === 1"
            class="pl-6"
            @click="currentStep = 2"
          >
            {{ $t("common.next") }}
            <v-icon> mdi-chevron-right </v-icon>
          </base-button>
        </v-col>
      </v-row>
    </v-footer>
  </base-card>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import SubscriptionDeviceScan from "@components/admin/subscription/SubscriptionDeviceScan";
import SubscriptionCard from "@components/admin/subscription/SubscriptionCard";
import { workerService } from "@api/worker-service";

export default {
  name: "SubscriptionManagement",
  components: {
    SubscriptionCard,
    SubscriptionDeviceScan,
  },
  data() {
    return {
      currentStep: Number(this.step),
      portalLoading: false,
      loading: false,
      refreshTimerId: null,
      refreshInterval: 10 * 1000,
      totalFormatted: this.$t("common.loadingdot"),
    };
  },
  props: {
    step: {
      type: String,
      required: true,
      default: "1",
    },
  },
  computed: {
    ...mapState("subscription", {
      hardwareProducts: (state) => state.hardwareProducts,
    }),
    ...mapGetters(["getAllOwnedDevices"]),
    ...mapGetters("subscription", [
      "getSubtotal",
      "getOldSubtotal",
      "getIsSubscriptionUpdated",
      "getFormattedPrice",
    ]),
    ...mapGetters("auth", ["getIsProvidingService"]),
    resultIcon() {
      return this.getIsSubscriptionUpdated ? "mdi-check-circle" : "mdi-magnify";
    },
    resultText() {
      return this.getIsSubscriptionUpdated
        ? this.$t("subscription.upToDate")
        : this.$t("subscription.foundDevices");
    },
    subtotalTextLabel() {
      const subtotal = this.getSubtotal(this.getAllOwnedDevices);
      return this.formatPrice(subtotal);
    },
    oldSubtotalTextLabel() {
      const subtotal = this.getOldSubtotal(this.getAllOwnedDevices);
      return this.formatPrice(subtotal);
    },
  },
  methods: {
    async openCustomerPortal() {
      this.portalLoading = true;
      await this.$store.dispatch(
        "subscription/openCustomerPortal",
        "/admin/subscription"
      );
    },
    formatPrice(amount) {
      const currency = this.hardwareProducts.find(
        (product) => product.currency
      )?.currency;
      return this.getFormattedPrice(amount, currency);
    },
    async updateSubscription() {
      this.loading = true;
      await this.$store.dispatch(
        "subscription/updateSubscription",
        this.getAllOwnedDevices
      );
      window.location.href = "/admin/subscription";
    },
    async refresh() {
      await this.$store.dispatch("subscription/initSupportedDevices");
    },
  },
  async mounted() {
    await this.refresh();
    try {
      const { totalFormatted } = await workerService.fetchMetrics();
      this.totalFormatted = totalFormatted;
    } catch (err) {
      console.error(err);
    }
  },
  beforeDestroy() {
    clearInterval(this.refreshTimerId);
  },
};
</script>

<style lang="scss" scoped></style>
