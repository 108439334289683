<template>
  <v-card class="text-center">
    <v-card-title class="justify-center">
      {{ $t("subscription.clickUpdate") }}
    </v-card-title>

    <!--todo: -->
    <!--refactor / update (difference?) / new (timeline?) -->

    <v-card-subtitle>
      {{
        $t("subscription.newPayOldPay", {
          subtotalLabel,
          oldSubtotalLabel,
        })
      }}
    </v-card-subtitle>

    <v-card-actions>
      <v-spacer />
      <base-button
        :disabled="disabled"
        :loading="loading"
        class="pa-5"
        @click="onclick"
      >
        <v-icon class="mr-2"> mdi-sync </v-icon>
        {{ $t("subscription.update") }}
      </base-button>
      <v-spacer />
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "SubscriptionCard",
  props: {
    loading: {
      type: Boolean,
      required: true,
      default: false,
    },
    subtotalLabel: {
      type: String,
      required: true,
    },
    oldSubtotalLabel: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    onclick() {
      this.$emit("click", true);
    },
  },
};
</script>

<style scoped></style>
